<script setup>
import logo from "@/assets/SCDesign_header.png";
import scDesignUserManualPDF from "@/assets/help/scdesign_user_manual.pdf";
</script>

<template>
  <v-app-bar color="header" theme="dark" flat density="compact">
    <!-- ロゴ -->
    <div class="header-logo">
      <v-img :src="logo" height="100%" width="210px" cover />
    </div>
    <template v-slot:append>
      <!-- ヘルプ -->
      <v-btn icon>
        <v-icon @click="openHelpPDF()">
          icon:Help
        </v-icon>
      </v-btn>
      <!-- ユーザー設定 -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon color="white"> icon:User </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item>
            <div v-if="user">
              {{ user.family_name }} {{ user.given_name }} <br>
              {{ user.email }}
            </div>
          </v-list-item>
          <v-list-item @click="gotoAccountmanagement()">
            <v-list-item-title>
              <v-icon class="mr-4" size="small">icon:Setting</v-icon>
              {{ $t("Accountmanagement") }} (SMART CONSTRUCTION)
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="refresh()">
            <v-list-item-title> {{ $t("Refresh") }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    lang(lang) {
      this.$i18n.locale = lang
    },
    openHelpPDF() {
      window.open(scDesignUserManualPDF);
    },
    gotoAccountmanagement() {
      window.open(import.meta.env.VITE_ACCOUNT_PAGE)
    },
    refresh() {
      window.location.href = `${import.meta.env.VITE_API_BASE}/login`;
    }
  }
};
</script>

<style>
.v-toolbar__content {
  padding-left: 0px !important;
}

.header-logo {
  height: 100%;
  overflow: hidden;
}
</style>
